<template>
<div>
  <v-snackbar v-model="snackbar.visible" bottom rounded="pill"
              :color="snackbar.color"
              :timeout="snackbar.timeout"
              :multi-line="snackbar.mode === 'multi-line'"
              :right="snackbar.position === 'right'"
              :center="snackbar.position === 'center'">
    <v-layout align-center pr-4>
      <v-icon class="pr-3" dark>{{ snackbar.icon }}</v-icon>
      <v-layout column>
        <div v-html="snackbar.text"></div>
      </v-layout>
    </v-layout>
  </v-snackbar>
  <v-snackbar v-model="snackbarAddCart.visible" bottom light
              :color="snackbarAddCart.color"
              :timeout="snackbarAddCart.timeout"
              :multi-line="snackbarAddCart.mode === 'multi-line'"
              :right="snackbarAddCart.position === 'right'"
              :center="snackbarAddCart.position === 'center'"
              :width="smAndDown? '100%': '520px'"
  >
    <v-card
      class="elevation-0 mx-auto px-1 px-md-3"
      width="100%"
    >
      <v-card-title>
        <v-layout class="darken-4 d-flex font-weight-black pb-4"
                  :class="!smAndDown ?'justify-center text-center mx-auto text-subtitle-1': 'ml-3 text-subtitle-2 pt-8'">
          カートに商品が追加されました
        </v-layout>
        <v-btn
          :icon="true"
          class="ml-auto"
          :class="!smAndDown ? 'mt-n10 mr-n9': 'mt-n12 mr-n3'"
          @click="closeAddCartMessage"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-card light flat outlined>
          <div class="d-flex flex-no-wrap">
            <v-img
              class="ma-3"
              max-width="50px"
              tile>
              <v-img
                :src="snackbarAddCart.itemImageUrl"></v-img>
            </v-img>
            <div>
              <v-card-subtitle class="pb-0" v-text="snackbarAddCart.itemTitle"></v-card-subtitle>
              <v-card-subtitle class="py-0" v-if="snackbarAddCart.itemPrice > 0">
                {{ snackbarAddCart.itemPrice | addCommaSeparator }}円 <span
                class="text-caption">税込</span></v-card-subtitle>
              <v-card-subtitle class="pt-0 red--text text--darken-2">{{snackbarAddCart.itemConditionName }}</v-card-subtitle>
            </div>
          </div>
        </v-card>
        <v-list-item class="pl-0 py-4">
          <v-list-item-content class="text-body-1">
            <v-list-item-subtitle style="color: #2c2c2c">商品数 <span
              class="font-weight-bold">1点</span></v-list-item-subtitle>
            <v-list-item-subtitle style="color: #2c2c2c" v-if="snackbarAddCart.itemPrice > 0">ご注文金額 <span
              class="font-weight-bold">{{ snackbarAddCart.itemPrice | addCommaSeparator }}円</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>
      <v-card-actions>
        <v-btn
          block
          color="#a0330a"
          class="white--text font-weight-bold block"
          href="/cart/list"
        >
          カートを確認する
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-snackbar>
  <v-snackbar v-if="favoriteSnackbar" v-model="favoriteSnackbar.visible" bottom rounded="pill"
              :color="favoriteSnackbar.color"
              :timeout="favoriteSnackbar.timeout"
              :multi-line="favoriteSnackbar.mode === 'multi-line'"
              :right="favoriteSnackbar.position === 'right'"
              :center="favoriteSnackbar.position === 'center'">
    <v-layout align-center pr-4>
      <v-icon class="pr-3" dark>{{ favoriteSnackbar.icon }}</v-icon>
      <v-layout column>
        <div v-html="favoriteSnackbar.text"></div>
      </v-layout>
    </v-layout>
  </v-snackbar>
  <vb-modal :state="favoriteLoginModalState" ref="loginDialog" classname="spaced-modal">
    <div :style="smAndDown? 'width: 370px': 'width: 520px'">
      <modal-login></modal-login>
    </div>
  </vb-modal>
</div>
</template>

<script>
import {mapGetters, mapMutations } from "vuex";
import ModalLogin from "../modal-login.vue";
import VbModal from "../../atoms/vb-modal.vue";
export default {
  name: "vb-notification-messages",
  components: {VbModal, ModalLogin},
  computed: {
    ...mapGetters({
      snackbar: 'item/getSnackbar',
      snackbarAddCart: 'item/getSnackbarAddCart',
      favoriteSnackbar: 'favorite/getSnackbar',
      favoriteLoginModalState: 'favorite/getStateToLoginModal'
    }),
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  methods: {
    ...mapMutations('item', [
      'setSnackbarAddCart'
    ]),
    closeAddCartMessage() {
      this.setSnackbarAddCart({visible: false})
    }
  }
}
</script>


<style scoped lang="scss">
.v-tooltip__content {
  margin-top: 4px;
}
.login-modal .modal-header img {
  width: 50px !important;
  min-width: auto !important;
}

.login-modal .modal-body {
  border-bottom: 1px solid #DDD;
  margin: 25px 0 35px;
  padding: 0 64px 0;
}
.other-site-id ul {
  padding-left: 0 !important;
}
@media screen and (max-width: $breakpoint-md) {
  ::v-deep {
    .login-modal .modal-body {
      padding: 0;

      p {
        margin-bottom: 8px !important;
      }
    }

  }
}
</style>