<template>
  <v-app>
    <v-footer
      color="grey lighten-3"
      padless
      class="pt-md-14 pb-md-8 px-md-6"
    >
      <v-responsive width="100%" max-width="1024" class="mx-auto">
        <v-row
          no-gutters
          class="pt-12 pb-10 px-6 pa-md-0 mb-md-15 justify-space-between align-center"
        >
          <v-col class="d-none d-md-flex justify-center flex-wrap" md="auto" :style="smAndDown? '': 'width: 100px;'">
            <router-link to="/" class="col-12 d-block pa-0 mb-8">
              <v-img :src="cdnUrl + '/assets/images/footer/vb_logo.svg'" width="96" height="54" class="mx-auto"></v-img>
            </router-link>
            <v-btn
              v-for="(item, index) in snsItems"
              :key="index"
              text
              plain
              :href="item.href"
              target="_blank"
              class="pa-0 v-btn--active"
              :class="{'mr-5': (index + 1) !== snsItems.length}"
              min-width="auto"
              max-width="20">
              <v-img :src="item.image" :alt="item.alt"></v-img>
            </v-btn>
          </v-col>
          <v-col class="d-md-flex pr-md-14 mb-6 mb-md-0" cols="12" md="auto">
            <v-list flat color="transparent" class="py-0 mr-md-8">
              <template v-for="(item, index) in contentsItems.slice(0, 4)">
                <v-list-item
                  :key="index"
                  :href="item.href"
                  class="px-0"
                  :class="index === 3? 'mb-md-0': 'mb-md-6'"
                  style="min-height: auto"
                  :ripple="false"
                >
                  <v-list-item-title class="text-body-2 text-md-caption" :class="{'font-weight-bold': !smAndDown}">{{ item.text }}</v-list-item-title>
                </v-list-item>
                <v-divider class="d-md-none my-4" />
              </template>
            </v-list>
            <v-list flat color="transparent" class="py-0">
              <template v-for="(item, index) in contentsItems.slice(4, contentsItems.length)">
                <v-list-item
                  :key="index"
                  :href="item.href"
                  class="px-0 mb-md-6"
                  style="min-height: auto"
                  :ripple="false"
                >
                  <v-list-item-title class="text-body-2 text-md-caption" :class="{'font-weight-bold': !smAndDown}">{{ item.text }}</v-list-item-title>
                </v-list-item>
                <v-divider class="d-md-none my-4" />
              </template>
            </v-list>
          </v-col>
          <v-col cols="12" md="auto" class="pr-md-4">
            <v-card flat color="transparent">
              <v-card-title class="pa-0 mb-3 text-caption">その他のサービス</v-card-title>
              <v-list flat color="transparent" class="pa-0" width="min-content">
                <v-list-item
                  v-for="(item, index) in otherService"
                  :key="index"
                  :href="item.href"
                  target="_blank"
                  class="pa-0"
                  :class="{'mb-4': (index + 1) !== otherService.length}"
                  style="min-height: auto"
                  :ripple="false"
                >
                  <v-list-item-icon class="my-0">
                    <v-img :src="item.image" width="60" :alt="item.title" />
                  </v-list-item-icon>

                  <v-list-item-content class="pa-0">
                    <v-list-item-title v-text="item.title" class="text-caption font-weight-bold mb-1" />
                    <v-list-item-subtitle v-text="item.text" style="font-size: 10px" />
                  </v-list-item-content>

                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="auto" v-if="$vuetify.breakpoint.mdAndUp">
            <v-img :src="cdnUrl + '/assets/images/footer/charity_banner.svg'" width="128" height="auto" class="ml-auto"></v-img>
          </v-col>
        </v-row>
        <v-sheet tile :color="smAndDown? '#cacaca': 'transparent'">
          <v-row
            no-gutters
            class="pt-12 pb-10 px-6 pa-md-0 justify-space-between">
            <v-col class="d-md-none mb-10 d-flex justify-center flex-wrap" cols="12">
              <router-link to="/" class="col-12 pa-0 mb-6">
                <v-img :src="cdnUrl + '/assets/images/footer/vb_logo_sp.svg'" width="160" height="16" class="mx-auto"></v-img>
              </router-link>
              <v-btn
                v-for="(item, index) in snsItems"
                :key="index"
                text
                :href="item.href"
                target="_blank"
                class="pa-0"
                :class="{'mr-8': (index + 1) !== snsItems.length}"
                min-width="auto"
                max-width="20">
                  <v-img :src="item.image" :alt="item.alt"></v-img>
              </v-btn>
            </v-col>
            <v-col cols="12" v-if="!$vuetify.breakpoint.mdAndUp">
              <v-img :src="cdnUrl + '/assets/images/footer/charity_banner.svg'" width="128" height="auto" class="mx-auto mb-6"></v-img>
            </v-col>

            <g-recaptcha-notice />
            <v-col
              cols="12"
              md="auto"
              class="d-flex flex-wrap mb-8 mb-md-0"
              :class="smAndDown? 'justify-center': 'justify-space-between'">
              <template v-for="(link, index) in navigationItems">
                <v-btn
                  :key="index"
                  text
                  rounded
                  plain
                  :href="link.href"
                  :target="link.external? '_blank': null"
                  :ripple="false"
                  color="grey darken-4"
                  min-width="auto"
                  height="auto"
                  class="my-0 px-0 mx-3 mx-md-0"
                  :class="{'mr-md-4': (index + 1) !== navigationItems.length}"
                  style="font-size: 10px"
                >
                  {{ link.text }}
                </v-btn>
                <v-spacer v-if="smAndDown && index === 1" class="col-12" />
              </template>
            </v-col>
            <v-col
              cols="12"
              md="auto"
              class="py-0 text-md-right text-center grey--text text--darken-4"
              style="font-size: 10px;transform: scale(0.8);"
              :style="smAndDown? '': 'transform-origin: right bottom;'">
              COPYRIGHT &copy; VALUE BOOKS ALL RIGHTS RESERVED.
            </v-col>
          </v-row>
        </v-sheet>
      </v-responsive>
    </v-footer>
  </v-app>
</template>

<script>

import GRecaptchaNotice from "../atoms/g-recaptcha-notice.vue";

export default {
  name: "vb-footer",
  components: {
    GRecaptchaNotice
  },
  props: {
    previousPageUrl: {
      type: String,
      required: false,
      default: null
    }
  },
  data: () => ({
    cdnUrl: VueConfig.cdn_url,
    contentsItems: [
      {
        href: '/estimate/guide',
        text: 'おためし査定'
      },
      {
        href: '/sell/faq',
        text: 'よくある質問'
      },
      {
        href: '/endpaper/8031/',
        text: 'はじめての方へ'
      },
      {
        href: '/help/regist',
        text: 'ご利用方法'
      },
      {
        href: '/mypage/faq_shopping',
        text: 'お問い合わせ'
      },
      {
        href: '/partner',
        text: 'パートナーシップ・プログラム'
      },
    ],
    navigationItems: [
      {
        href: '/privacy',
        text: '個人情報の取扱について'
      },
      {
        href: '/help/dealings',
        text: '特定商取引法に基づく表記'
      },
      {
        href: '/rules',
        text: '利用規約'
      },
      {
        href: 'https://corporate.valuebooks.jp/company/',
        text: '会社概要',
        external: true
      },
      {
        href: 'https://corporate.valuebooks.jp/recruit/',
        text: '採用情報',
        external: true
      },
    ],
    otherService: [
      {
        image: VueConfig.cdn_url + '/assets/images/footer/logo_vaboo.png',
        title: 'Vaboo / バブー',
        text: '会員登録不要で気軽に古本買取',
        href: VueConfig.vaboo_url
      },
      {
        image: VueConfig.cdn_url + '/assets/images/footer/logo_charibon.png',
        title: 'charibon / チャリボン',
        text: '本で社会に寄付する取り組みをしています',
        href: VueConfig.charibon_url
      }
    ],
    snsItems: [
      {
        href: VueConfig.instagram_url,
        image: VueConfig.cdn_url + '/assets/images/footer/insta.svg',
        alt: 'VALUE BOOKS公式Instagram'
      },
      {
        href: VueConfig.x_url,
        image: VueConfig.cdn_url + '/assets/images/footer/x.svg',
        alt: 'VALUE BOOKS公式X'
      },
      {
        href: VueConfig.line_url,
        image: VueConfig.cdn_url + '/assets/images/footer/line.svg',
        alt: 'VALUE BOOKS公式LINE'
      }
    ],
    couponKeys: {
      buy: {
        urlParamsName: VueConfig.couponKeyForCookies.buy.urlParamsName,
        cookieName: VueConfig.couponKeyForCookies.buy.cookieName
      },
      purchase: {
        urlParamsName: VueConfig.couponKeyForCookies.purchase.urlParamsName,
        cookieName: VueConfig.couponKeyForCookies.purchase.cookieName
      },
    }
  }),
  created() {
    for (const couponType in this.couponKeys) {
      this.findURLParamsCouponCode(couponType);
    }
  },
  computed: {
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    findURLParamsCouponCode(couponType) {
      const urlKeyName = this.couponKeys[couponType].urlParamsName;
      let referrerSearchParamsCouponCode = null;
      // Laravelで未ログイン時のログイン画面への転送時にURLパラメータの取得ができない為、auth_refererにセットされたURLのパラメータを検索
      // redirectの場合、document.referrerでは取得不可
      if(this.previousPageUrl) {
        const baseUrl = location.protocol + location.hostname;
        referrerSearchParamsCouponCode = new URLSearchParams(new URL(this.previousPageUrl, baseUrl).search).get(urlKeyName);
      }
      const searchParamsCouponCode = new URLSearchParams(window.location.search).get(urlKeyName);
      const paramsCouponCode = searchParamsCouponCode ? searchParamsCouponCode : referrerSearchParamsCouponCode;
      if(paramsCouponCode) {
        this.setCouponCodeCookie(couponType, paramsCouponCode);
      }
    },
    // URLパラメータにクーポンコードがある場合cookieにセットする
    setCouponCodeCookie(couponType, couponCodeValue) {

      const hour = 3; // cookieの有効期限(h)
      const keyName = this.couponKeys[couponType].cookieName;
      const keyValue = couponCodeValue;

      let date = new Date();
      date.setTime( date.getTime() + ( hour * 60 * 60 * 1000 ));

      document.cookie = keyName + '=' + keyValue + ';expires=' + date + ';path=/';
    },
  }
}
</script>

<style lang="scss">
  #vb-app {
    .v-application--wrap {
      min-height: fit-content;
    }
  }
  select, textarea {
    border: 1px solid #ccc;
  }
  .v-btn:hover::before,
  .v-tab:hover::before
  {
    opacity: 0.08 !important;
    color: #2c2c2c !important;
  }

  .v-btn--text:before
  {
    left: -4px !important;
    right: -4px !important;
    border-radius: 4px !important;
  }
  .v-tab:before
  {
    border-radius: 4px !important;
  }
</style>
<style scoped lang="scss">
   ::v-deep{
     .v-application--wrap {
       min-height: auto;
     }
   }


</style>