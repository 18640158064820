<template>
  <v-app>
    <v-app-bar
        app
        dense
        flat
        elevate-on-scroll
        color="white"
        min-width="auto"
        height="fit-content"
        class="py-2 pt-md-6 pb-md-4"
        style="z-index: 9"
        :style="smAndDown ? null : 'border-bottom: 1px solid #efefef !important'"
    >
      <a href="/">
        <v-img
          :src="$vuetify.breakpoint.width < 720 ? cdnUrl + '/assets/images/header/logo.svg' : cdnUrl + '/assets/images/common/logos/value_books.svg'"
          contain
          max-height="18px"
          width="auto"
          max-width="170"
          class="mr-4 mr-md-7"
          :key="$vuetify.breakpoint.width < 720 ? 'small': 'large'" />
      </a>
      <v-sheet class="d-none d-md-flex" min-width="400px">
        <v-btn depressed
               class="white--text"
               color="#0095d0"
               max-width="136"
               href="/sell">
          <v-icon class="mr-2" style="max-width: 22px">mdi-truck-outline</v-icon>
          買取はこちら
        </v-btn>
        <v-btn depressed
               class="pl-1 pr-2 ml-3"
               color="white"
               href="/shelf-items">
          <v-icon class="mr-1" style="max-width: 22px">mdi-equalizer</v-icon>
          ライブラリ
        </v-btn>
        <v-btn depressed
               class="pl-1 pr-2 ml-3"
               color="white"
               href="/endpaper/">
          <v-icon class="mr-2" style="max-width: 22px">mdi-book-open-variant</v-icon>
          読みもの
        </v-btn>
      </v-sheet>
      <v-spacer></v-spacer>

      <v-combobox
        dense
        class="search-input text-caption mr-md-4"
        :class="smAndDown ? 'px-1' : 'px-2'"
        :search-input.sync="inputText"
        placeholder="気になる本を検索"
        filled
        clear-icon="mdi-close-circle"
        clearable
        rounded
        :items="KeywordSuggestions"
        hide-details
        hide-no-data
        hide-selected
        background-color="#f5f5f5"
        style="max-width: 320px;"
        @keydown="execKeywordSearch"
        :value="inputText"
        :append-icon="null"
        @change="suggestedSelection"
      >
        <template #prepend-inner>
          <v-icon @click="search" color="#2c2c2c" :style="xxsAndDown ? 'font-size: 22px' : null">mdi-magnify</v-icon>
        </template>
        <template #append>
          <template v-if="smAndDown">
            <v-icon v-if="!isSkipped" @click="scanDialog = true" color="#0095d0" :style="xxsAndDown ? 'font-size: 22px' : null">mdi-camera</v-icon>
            <label v-else for="bookshelf-image-file-input"><v-icon color="#0095d0" :style="xxsAndDown ? 'font-size: 22px' : null">mdi-camera</v-icon></label>
          </template>
        </template>
      </v-combobox>
      <v-badge
        id="badge-my-page-info"
        color="#d02"
        :content="infoCount"
        offset-x="20"
        offset-y="10"
        :class="{ 'no-cnt': infoCount === 0 }"
      >
        <v-btn depressed
               icon
               :width="xxsAndDown ? 32 : 40"
               :height="xxsAndDown ? 32 : 40"
               class="mr-md-1"
               href="/mypage">
          <v-icon color="#2c2c2c" :style="xxsAndDown ? 'font-size: 22px' : null">mdi-account</v-icon>
        </v-btn>
      </v-badge>
      <v-badge
        id="badge-cart-items"
        color="#d02"
        :content="numberOfCartItems"
        offset-x="20"
        offset-y="10"
        :class="{ 'no-cnt': numberOfCartItems === 0 }"
      >
        <v-btn depressed
               icon
               :width="xxsAndDown ? 32 : 40"
               :height="xxsAndDown ? 32 : 40"
               href="/cart/list">
          <v-icon color="#2c2c2c" :style="xxsAndDown ? 'font-size: 22px' : null">mdi-cart</v-icon>
        </v-btn>
      </v-badge>
      <v-app-bar-nav-icon class="d-md-none" @click.stop="drawer = !drawer" :style="xxsAndDown ? 'width: 32px; height: 32px' : 'width: 40px; height: 40px'"></v-app-bar-nav-icon>
    </v-app-bar>

    <v-row v-if="smAndDown" dense no-gutters class="pt-1 pb-2" :style="smAndDown ? 'border-bottom: 1px solid #efefef' : null">
      <v-col cols="4" class="text-center pl-1">
        <v-btn href="/sell" color="#0095d0" elevation="0" class="text-caption white--text px-2" max-width="136"><v-icon color="white" class="mr-2" :small="xxsAndDown">mdi-truck-outline</v-icon>買取はこちら</v-btn>
      </v-col>
      <v-col cols="4" class="text-center px-4">
        <v-btn href="/shelf-items" text class="text-caption px-0"><v-icon color="#2c2c2c" class="mr-1" :small="xxsAndDown">mdi-equalizer</v-icon>ライブラリ</v-btn>
      </v-col>
      <v-col cols="4" class="text-center px-4">
        <v-btn href="/endpaper/" text class="text-caption px-0"><v-icon color="#2c2c2c" class="mr-2" :small="xxsAndDown">mdi-book-open-variant</v-icon>読みもの</v-btn>
      </v-col>
    </v-row>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      width="100%"
      height="calc(100% - 57px)"
      style="top: 57px; z-index: 9"
      hide-overlay
      color="#f5f5f5"
    >
      <v-list
        nav
        dense
        class="px-6 pt-6"
        color="transparent"
      >
        <v-list-item href="/sell" class="mb-4">
          <v-list-item-title class="text-body-2 font-weight-bold"><v-icon color="#2c2c2c" class="mr-2" :style="xxsAndDown ? 'font-size: 22px' : 'font-size: 28px'">mdi-truck</v-icon>買取申込み</v-list-item-title>
        </v-list-item>

        <v-list-item href="/shelf-items" class="mb-4">
          <v-list-item-title class="text-body-2 font-weight-bold"><v-icon color="#2c2c2c" class="mr-1" :style="xxsAndDown ? 'font-size: 22px' : 'font-size: 28px'">mdi-equalizer</v-icon>ライブラリ</v-list-item-title>
        </v-list-item>

        <v-list-item href="/endpaper/" class="mb-4">
          <v-list-item-title class="text-body-2 font-weight-bold"><v-icon color="#2c2c2c" class="mr-2" :style="xxsAndDown ? 'font-size: 22px' : 'font-size: 28px'">mdi-book-open-variant</v-icon>読みもの</v-list-item-title>
        </v-list-item>

        <v-list-item href="/estimate/guide">
          <v-list-item-title>おためし査定</v-list-item-title>
        </v-list-item>
        <v-list-item href="/sell/faq">
          <v-list-item-title>よくある質問</v-list-item-title>
        </v-list-item>
        <v-list-item href="/endpaper/8031/">
          <v-list-item-title>はじめての方へ</v-list-item-title>
        </v-list-item>
        <v-list-item href="/mypage/faq_shopping">
          <v-list-item-title>お問い合わせ</v-list-item-title>
        </v-list-item>
        <v-list-item href="/logout">
          <v-list-item-title>ログアウト</v-list-item-title>
        </v-list-item>
        <v-list-item href="/signup">
          <v-list-item-title>新規登録</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-dialog
      v-model="scanDialog"
      @click:outside="scanDialog = false"
      max-width="640"
    >
      <v-card flat tile class="pa-6">
        <v-card-title class="pa-0 text-body-1 font-weight-bold">本棚スキャンの使い方
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            icon
            @click="scanDialog = false"
          >
            <v-icon color="#2c2c2c">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0 mb-1 text-caption">
          下のボタンを押すとカメラが起動します。本の背表紙が横に並んだ状態で撮影してみてくださいね。
          <v-img width="50%" class="mx-auto" :src="cdnUrl + '/assets/images/estimate/bookshelf_assessment_scan_illust.svg'" alt="本棚を撮影する"></v-img>
        </v-card-text>
        <v-card-actions class="pa-0 mb-6 d-flex flex-column">
          <v-btn tile elevation="0" color="black" class="py-4 px-8" width="100%" height="auto" tag="label" for="bookshelf-image-file-input">
            <span class="white--text">本棚スキャンを始める</span>
          </v-btn>
          <p class="d-none red--text text-caption mt-1 mb-0" :class="smAndDown && isWebview ? 'd-block':'d-none'">※スキャンが正しく起動しない場合は、Chrome/Safariなどのブラウザアプリからお試しください。</p>
        </v-card-actions>
        <v-card-text class="pa-0 text-center text-caption">
          一冊ずつ正確な査定をご希望の方は
        </v-card-text>
        <v-card-actions class="pa-0 text-center">
          <v-btn text class="text-decoration-underline mx-auto" tag="label" for="take-picture-button">
            <span class="black--text text-caption">バーコードスキャン</span>
          </v-btn>
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-text>
          <v-checkbox hide-details @change="toggleCookie($event)">
            <template #label>
              <span class="text-body-2">次回以降はこの表示を省略する</span>
            </template>
          </v-checkbox>
        </v-card-text>
      </v-card>
    </v-dialog>
    <form id="form_item_search" action="/search">
      <input type="hidden" name="conditions_stock" value="1"/>
      <input id="search-query" type="hidden" name="keyword" autocomplete="off">
    </form>
    <v-file-input
      class="d-none"
      :value="bookshelfImageFile"
      @change="execBookshelfScan"
      accept="image/*"
      label="本棚スキャンを始める"
      id="bookshelf-image-file-input"
    >
    </v-file-input>

    <v-dialog
      v-model="sizeOverDialog"
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-center">
          <span class="headline">エラー</span>
        </v-card-title>
        <v-card-text>
          <p>本棚画像のファイルサイズが大きすぎます。</p>
          <p>解像度を下げて、再度おためしください。</p>
          <p>また、本棚スキャンについて詳しくは「<a href="/sell/faq#faq-search">よくある質問</a>」をご覧下さい。</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="sizeOverDialog = false"
          >
            閉じる
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn
      v-if="smAndDown && showFloatButton && !isExcludedRoute()"
      fixed
      bottom
      right
      style="z-index: 1"
      text
      height="auto"
      class="pa-0"
      tag="label"
      for="bookshelf-image-file-input"
      >
      <v-img :src="`${cdnUrl}/assets/images/common/fixed_icon_bookshelf_scale_animation.svg`" width="93" height="105"></v-img>
    </v-btn>
    <vb-notification-messages></vb-notification-messages>
  </v-app>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'
import searchConfig from '../../config/search.js';
import VbNotificationMessages from "../molecules/item/vb-notification-messages.vue";

const {mapState, mapActions, mapMutations, mapGetters} = createNamespacedHelpers('bookshelfScan')
const MAX_FILE_SIZE = 10 * 1024 * 1024;  // S3へアップロード可能な画像の最大サイズ

export default {
  name: "vb-header",
  components: {VbNotificationMessages},
  created() {
    // keywordにクエリがある場合はinputTextにセット
    const url = new URL(location.href);
    const keyword = url.searchParams.get('keyword');
    if (keyword) {
      this.inputText = keyword;
    }
  },
  props: {
    numberOfCartItems: {
      type: Number,
      default: 0
    },
    numberOfMyPageInfo: {
      type: Number,
      default: 0
    }
  },
  data: function () {
    return {
      cdnUrl: VueConfig.cdn_url,
      dialog: false,
      bookshelfImageFile: null,
      searchWasTriggered: false,
      KeywordSuggestions: [],
      inputText: null,
      drawer: false,
      dateFormat: "YYYY-MM-DD HH:mm:ss",
      refreshInfoKey: 'refreshInfo',
      infoCount: this.numberOfMyPageInfo,
      isSkipped: false,
      isWebview: false,
      sizeOverDialog: false,
      showFloatButton: false
    }
  },
  methods: {
    ...mapActions([
      'openDialog',
      'closeDialog',
      'updateBookshelfScanImage',
      'saveStorage'
    ]),
    ...mapMutations([
      'mutateBookshelfScanImageFile',
      'mutateScanDialog'
    ]),
    execKeywordSearch(event) {
      if(event.keyCode === 13) {
        this.search();
      }
    },
    async execBookshelfScan(event) {
      if (event.size > MAX_FILE_SIZE) {
        this.sizeOverDialog = true;
        return;
      }
      await this.saveStorage(event);
      location.href = '/spa/estimate/search';
    },
    suggestedSelection(event) {
      if(event) {
        this.inputText = event;
        this.search();
      }
    },
    search() {
      if(this.searchWasTriggered) {// 二重送信を防ぐ処理
        return;
      }
      this.searchWasTriggered = true;

      searcingPopup.popup('show');

      const q = this.inputText ? this.inputText : '';

      let form = document.createElement('form');
      form.id = 'form_item_search';
      form.action = '/search';
      document.body.appendChild(form);

      let input = document.createElement('input');
      input.type = 'hidden';
      input.name = 'keyword';
      input.value = q;
      form.appendChild(input);

      let tempKeyWords = this.buildTranscriptions(q);
      let keyword_readings = document.createElement('input')
      keyword_readings.type = 'hidden';
      keyword_readings.name = 'kana_words';
      keyword_readings.value = JSON.stringify(tempKeyWords[`kanaWords`]);
      form.appendChild(keyword_readings);

      let other_readings = document.createElement('input')
      other_readings.type = 'hidden';
      other_readings.name = 'other_readings';
      other_readings.value = JSON.stringify(tempKeyWords[`others`]);
      form.appendChild(other_readings);
      setTimeout(function() {
        searcingPopup.popup('hide');
      }, 5000);

      form.submit();

      // set serachWasTriggered to false after 3 seconds
      setTimeout(function() {
        this.searchWasTriggered = false;
      }, 3000);
    },
    hinter() {
      if(!this.inputText) {
        return;
      }
      this.KeywordSuggestions = [];
      const search_input = this.inputText.trim();

      let body = {'suggest': search_input};

      axios.post('/api/search/suggest', body)
        .then(response => {
          this.KeywordSuggestions = response.data;
        })
        .catch(error => {
          if (error.response && error.response.status === 400) {
            if (window.confirm("ページの有効期限が切れました。画面を再描画します。\nよろしいですか？")) {
              location.reload();
            }
          } else {
            console.error(error);
          }
        });
    },
    buildTranscriptions(text) {
      text = text.replace(/^(著者|出版社):/,"");
      let kanaText = this.convertRomanToKana(text);
      let kanaWords = [];
      let others = [];
      kanaText.split(/\s+/).forEach(function(keyword) {
        if (keyword.match(/^[ぁ-んァ-ヶー\s0-9０-９]+$/)) {//変換対象からローマ字外した
          // if (keyword.match(/^[ぁ-んァ-ヶー\sa-zA-Z0-9Ａ-Ｚａ-ｚ０-９]+$/)) {
          kanaWords.push(keyword);
        } else {
          others.push(keyword);
        }
      });
      let keywords = {'kanaWords': kanaWords,'others': others}

      return keywords;
    },
    convertRomanToKana(original) {
      const tree = searchConfig.TREE;
      const str = original.replace(/[Ａ-Ｚａ-ｚ]/g, s => String.fromCharCode(s.charCodeAt(0) - 65248)).toLowerCase(); // 全角→半角→小文字
      let result = '';
      let tmp = '';
      let index = 0;
      const len = str.length;
      let node = tree;
      const push = (char, toRoot = true) => {
        result += char;
        tmp = '';
        node = toRoot ? tree : node;
      };
      while (index < len) {
        const char = str.charAt(index);
        push(tmp + char);
        index++;
      }

      tmp = tmp.replace(/n$/, 'ン'); // 末尾のnは変換する
      push(tmp);
      return result;
    },
    isRefreshInfo(lastTime, now) {
      const lastTimeDate = moment(lastTime, this.dateFormat);
      const expireDate = lastTimeDate.add(30, 'minutes');
      return expireDate.diff(now) < 0;
    },
    updateInfoCount() {
      const lastTime = localStorage.getItem(this.refreshInfoKey);
      const now = moment(new Date(), this.dateFormat);

      if (lastTime === null) {
        // 初回ならばストレージに現在時刻を設定
        localStorage.setItem(this.refreshInfoKey, now.format(this.dateFormat));
      } else {
        // お知らせ件数更新
        if (this.isRefreshInfo(lastTime, now)) {
          fetch("/ajax/info/cnt", { method: "GET", cache: "no-cache" })
            .then(response => response.json())
            .then(data => {
              this.infoCount = data.cnt;

              // ストレージに現在時刻を再設定
              localStorage.setItem(this.refreshInfoKey, now.format(this.dateFormat));
            })
            .catch(error => {
              console.log(error);
            });
        }
      }
    },
    adjustBodyPadding() {
      const header = document.querySelector('header');
      if (header) {
        const headerHeight = header.offsetHeight;
        document.body.style.paddingTop = `${headerHeight}px`;
      }
    },
    toggleCookie(value) {
      if (value) {
        document.cookie = 'barcode-start-check_selected_value=on; path=/';
      } else {
        document.cookie = 'barcode-start-check_selected_value=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
      }
    },
    checkCookie() {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith('barcode-start-check_selected_value=on')) {
          this.isSkipped = true;
          break;
        }
      }
    },
    checkWebview() {
      if (navigator.userAgentData) {
        // navigator.userAgentDataが利用可能な場合
        navigator.userAgentData.getHighEntropyValues(['platform', 'brand'])
          .then(uaData => {
            const brands = uaData.brands.map(brand => brand.brand);
            // 特定のアプリのブランドを配列で定義
            const webviewBrands = ['Facebook', 'Line', 'Instagram'];

            this.isWebview = brands.some(brand => webviewBrands.includes(brand));
          });
      } else {
        // navigator.userAgentDataが利用できない場合のフォールバック
        // 従来のUserAgent文字列による判定
        const userAgent = window.navigator.userAgent.toLowerCase();
        if (userAgent.indexOf("android") != -1) {
          // アプリ判定
          if (userAgent.indexOf("fbav") != -1) {
            /* Facebook */
            this.isWebview = true;
          } else if (userAgent.indexOf("line") != -1) {
            /* LINE */
            this.isWebview = true;
          } else this.isWebview = userAgent.indexOf("instagram") != -1;
        }
      }
    },
    isAndroid() {
      if (navigator.userAgentData) {
        const brands = navigator.userAgentData.platform.toLowerCase();
        return  brands.indexOf("android") !== -1;
      } else {
        return  /Android/i.test(navigator.userAgent);
      }
    },
    isExcludedRoute() {
      const excludedRoutes = [
        /^\/$/,
        /^\/search/,
        /^\/ai-search/,
        /^\/ai-book-select/,
        /^\/anniversary/,
        /^\/library/,
        /\/bp\/VS/,
        /\/bp\/VC/,
        /^\/cart\/list/,
        /^\/shelf-items/
      ];
      const currentRoute = this.$route.path;
      return excludedRoutes.some(route => route.test(currentRoute));
    },
    showScanFloatButton() {
      this.showFloatButton = window.scrollY > 100;
    }
  },
  computed: {
    ...mapGetters([
      'getScanDialog'
    ]),
    scanDialog: {
      get() {
        return this.getScanDialog;
      },
      set(value) {
        this.mutateScanDialog(value);
      }
    },
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    xxsAndDown() {
      return this.$vuetify.breakpoint.width <= 320;
    }
  },
  mounted() {
    if(this.$customerId) {
      this.updateInfoCount();
      setInterval(this.updateInfoCount, 1000 * 60); // 1分
    }

    document.addEventListener('DOMContentLoaded', () => {
      // ページ読み込み時の余白を調整
      this.adjustBodyPadding();
      // リサイズ時の余白を調整
      window.addEventListener('resize', this.adjustBodyPadding);
      // this.initializeBarcodeReader();
    });
    this.checkCookie();
    this.checkWebview();
    if (this.isAndroid()) {
      document.getElementById("bookshelf-image-file-input").setAttribute('capture', 'camera');
    }
    window.addEventListener('scroll', this.showScanFloatButton);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.adjustBodyPadding);
    window.removeEventListener('scroll', this.showScanFloatButton);
  },
  watch: {
    inputText: function (val) {
      if (val) {
        this.hinter();
      }
    }
  }

}
</script>

<style scoped lang="scss">
::v-deep{
  .v-application--wrap {
    min-height: auto;
  }
  .search-input {
    .v-input__slot {
      padding: 0 12px;
      @media screen and (max-width: 393px) {
        padding: 0 8px !important;
      }
    }
    .v-select__slot {
      input {
        background-color: transparent;
        border: 0;
        padding: 0;
        &::placeholder {
          font-size: 12px;
          @media screen and (max-width: 320px) {
            font-size: 10px !important;
          }
        }
      }
    }
    .mdi-close-circle {
      color: #cacaca !important;
      font-size: 20px;
    }
    .mdi-menu-down {
      display: none;
    }
    input[type="text"] {
      font-size: 12px;
    }
  }
  .v-navigation-drawer--open {
    transform: translateY(0%) !important;
  }
  .v-navigation-drawer--close {
    transform: translateY(100%) !important;
  }
  #badge-my-page-info.no-cnt,
  #badge-cart-items.no-cnt {
    .v-badge__badge {
      display: none;
    }
  }
  .v-badge__badge {
    font-size: 10px !important;
    min-width: 14px !important;
    height: 14px !important;
    padding: 2px 3px !important;
  }
  .v-dialog--active {
    border-radius: 0 !important;
    margin-left: 12px;
    margin-right: 12px;
  }
  .v-dialog--fullscreen {
    margin-left: 0;
    margin-right: 0;
  }
}


</style>